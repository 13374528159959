<template>
  <v-content>
    <v-breadcrumbs :items="items" large></v-breadcrumbs>

    <v-snackbar
      v-model="snackbar"
      color="success"
      :timeout="callbackResponse.timeout"
      top
    >
      {{ callbackResponse.message }}
      <v-btn dark text @click="snackbar = false">{{$t('close')}}</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogEditRoom" max-width="500px">
      <editForm
        v-if="dialogEditRoom"
        :editData="editedDataService.hotelCustomerServiceID"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponseDelete"
      ></deleteListData>
    </v-dialog>

    <v-dialog v-model="dialogCreateRoom" max-width="500px">
      <template v-slot:activator="{ on }">
          <v-btn color="primary"
                 dark
                 class="ma-4 float-right"
                 v-on="on"
                 :success="success">{{$t('add_service')}}</v-btn>
      </template>
      <createForm :success="success" @formResponse="onResponse" />
    </v-dialog>
    <v-container fluid>
        <vue-good-table mode="remote"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        :totalRows="totalRecords"
                        :isLoading.sync="isLoading"
                        :pagination-options="{
          enabled: true,
          perPageDropdown: [20, 50, 100, 200],
        }"
                        :rows="roomRows"
                        :columns="columnsRoom">
            <template slot="table-column" slot-scope="props">
                <span>
                    {{ $t(props.column.label) }}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                    <v-icon small class="mr-2" @click="editItemRoom(props.row)">edit</v-icon>
                    <v-icon small @click="deleteItemRoom(props.row)">delete</v-icon>
                </span>
            </template>
        </vue-good-table>
    </v-container>
  </v-content>
</template>

<script>
import axios from "axios";
import createForm from "./CreateService";
import editForm from "./EditService";
import deleteListData from "@/components/deleteModal";
import { parseJwt } from "@/mixins/parseJwt";

export default {
  name: "List",
  mixins: [parseJwt],
  watch: {
    dialog(val) {
      val || this.close();
    },
    success: {
      handler: function(val, oldVal) {
        console.log(val, oldVal);
        this.loadItemsRoom();
      },
      deep: true,
    },
  },
  components: {
    createForm,
    editForm,
    deleteListData,
        },
        computed: {
            items() {
                return [
                    {
                        text: this.$t('settings'),
                        to: "/HotelSettings",
                        disabled: false,
                        exact: true,
                    },
                    {
                        text: this.$t('service_setting'),
                        disabled: true,
                    },
                    ]
            }
            },
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      snackbar: false,
      success: false,
      dialogCreateRoom: false,
      dialogEditRoom: false,
      dialogDelete: false,
      panel: [0],
      deleteFormData: {},
      popout: true,
      isLoading: false,
      columnsRoom: [
        {
          label: 's_no',
          field: "countIndex",
        },
        {
            label: 'customer_service_id',
          field: "hotelCustomerServiceID",
        },
        {
            label: 'customer_service',
          field: "hotelCustomerService",
        },
        {
            label: 'service_description',
          field: "hotelServiceDescription",
        },
        {
            label: 'service_rate',
          field: "hotelServiceRate",
        },
        {
          label: 'actions',
          field: "actions",
          sortable: false,
        },
      ],
     
      roomRows: [],
      totalRecords: 0,
      serverParamsRoom: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "hotelCustomerServiceID",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 20,
      },

      user: [],
      editedIndex: -1,
      editedDataService: {},
    };
  },

  mounted() {
    this.loadItemsRoom();
    this.filterData();
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    onResponse(data) {
      if (data) {
        this.snackbar = true;
        this.dialogCreateRoom = false;
        this.dialogEditRoom = false;
        this.callbackResponse = data;
        this.loadItemsRoom();
      }
    },
    onResponseDelete(data) {
      if (data) {
        this.snackbar = true;
        this.callbackResponse = data;

        this.dialogDelete = false;
        this.loadItemsRoom();
      } else {
        this.dialogDelete = false;
      }
    },
    editItemRoom(props) {
      this.editedDataService = props;
      this.dialogEditRoom = true;
      // this.dialogEditFare = true;
    },

    deleteItemRoom(props) {
      console.log({ props });
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Hotel/DeleteHotelCustomerAsync/" + props.hotelCustomerServiceID;
      this.loadItemsRoom();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    updateParams(newProps) {
      this.serverParamsRoom = Object.assign(
        {},
        this.serverParamsRoom,
        newProps
      );
    },

    searchInput() {
      this.loadItemsRoom();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItemsRoom();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItemsRoom();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItemsRoom();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItemsRoom();
    },

    async filterData() {},
    // load items is what brings back the rows from server
    async loadItemsRoom() {
      console.log(this.tokenData);
      // eslint-disable-next-line no-console
      let param = {
        PageSize: this.serverParamsRoom.perPage,
        PageNo: this.serverParamsRoom.page,
        OrderType: this.serverParamsRoom.sort[0].type,
        OrderBy: this.serverParamsRoom.sort[0].field,
      };
      try {
        const response = await axios.post(
          "Hotel/GetHotelCustomerServicesListAsync",
          param
        );
        this.roomRows = response.data.data;
        this.totalRecords = response.data.totalCount;
      } catch (e) {
        if (e.response.data.State == "AccessTokenExpired") {
          this.apiResponse(e);
          this.loadItemsRoom();
        }
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  background: #d2e3ff73;
}

.v-expansion-panel-header {
  background: #d2e3ff73;
}

button {
  z-index: 1;
}
</style>
